import Image from "next/image";
import { GeneralConfig } from "../../services/StrapiApi";

const Partners3 = ({ generalConfig }: { generalConfig: GeneralConfig }) => {
  const { brands } = generalConfig;

  return (
    <>
      <div className="dark:bg-jacarta-900 ">
        <div className="container">
          <div className=" grid grid-cols-2 py-8 sm:grid-cols-5">
            {brands.map((item, index) => (
              <a
                href={item.link}
                target="_blank"
                rel="noopener noreferrer"
                key={index}
                className="h-[103px]"
              >
                <div className="w-full h-full relative">
                  <Image
                    fill
                    src={item.logo.thumbnail}
                    alt="partner 1"
                    style={{ objectFit: "contain" }}
                  />
                </div>
              </a>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Partners3;
